<template>
	<div class="small soft">
		A {{ room.isPrivate ? 'private ' : '' }}room by
		<router-link :to="{ name: 'User', params: { username: room.owner.username } }">{{
			room.owner.name
		}}</router-link>
		<template v-if="room.featured"> &nbsp;&nbsp;•&nbsp;&nbsp; {{ prettyDate(room.featured) }}</template>
		<template v-else>.</template>
	</div>
</template>

<script>
import { prettyDate } from '@/use/StringMagic'

export default {
	name: 'BaseRoomDescription',
	props: { room: { type: Object, required: true } },
	setup() {
		return { prettyDate }
	},
}
</script>

<style scoped lang="scss">
// .date {
// 	color: $black-20;
// }
</style>
