<template>
	<div class="content-pad narrow">
		<ActionClose @click="$emit('exit')" />
		<h1 id="title">Edit {{ titlefy(this.params.type) }}</h1>

		<ClusterEditForm
			id="form"
			:params="params"
			@exit="$emit('exit')"
			@submit="updatedCluster => $emit('update', updatedCluster)"
		/>
	</div>
</template>

<script>
// Components
import ActionClose from '@/components/ActionClose'
import ClusterEditForm from '@/views/Room/ClusterEditForm'
import { titlefy } from '@/use/StringMagic'

export default {
	name: 'ClusterEdit',
	components: {
		ActionClose,
		ClusterEditForm,
	},
	props: {
		entity: Object,
		type: String,
	},
	emits: ['update', 'exit'],
	setup() {
		return { titlefy }
	},
	created() {
		console.log(this.entity)
	},
	data() {
		return {
			params: {
				type: this.type,
				id: this.entity.id,
				name: this.entity.name,
				intro: this.entity.intro,
				isPrivate: this.entity.isPrivate,
			},
		}
	},
}
</script>

<style scoped lang="scss">
.act-close {
	margin-top: calc(var(--pp) - 0.1rem);
	margin-right: calc(var(--pp) - 0.1rem);
}

#title {
	margin-bottom: 0.6rem;
}

#form,
#form:deep() #private-note {
	width: 5.4rem;
}
#form:deep() .wrap {
	width: 100%;
}

@media only screen and (max-width: $form) {
	#form {
		width: auto;
	}
	#form:deep() #private-note {
		width: auto;
	}
}

@media only screen and (max-width: $mobile) {
	#title {
		margin-bottom: 0.5rem;
	}
}
</style>
