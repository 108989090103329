<template>
	<div id="publish-bar">
		<ActionClose v-if="room.isPublished" @click="pageRoomStore.hideBar" />
		<FormButton
			v-if="!room.isPublished"
			:level="1"
			:value="['Publish', 'Publishing']"
			@click="pageRoomStore.publish"
			:disabled="!room.artworkCount"
			:wait="pageRoomStore.processing"
			title="Add some images to enable publishing"
		/>
		<FormButton
			v-else
			:level="2"
			value="Unpublish"
			@click="pageRoomStore.unpublish"
			:wait="pageRoomStore.processing"
		/>
		<FormToggle
			v-model="pageRoomStore.room.isPrivate"
			label="Private"
			@click="pageRoomStore.togglePrivate(pageRoomStore.room.isPrivate)"
		/>
		<div class="filler"></div>
	</div>
</template>

<script>
// Stores
import { usePageRoomStore } from '@/stores/PageRoomStore'
// import { useSessionStore } from '@/stores/SessionStore'

// Components
import FormButton from '@/components/FormButton'
import ActionClose from '@/components/ActionClose'
import FormToggle from '@/components/FormToggle'

export default {
	name: 'RoomPublishBar',
	components: {
		FormButton,
		ActionClose,
		FormToggle,
	},
	props: {
		room: Object,
	},
	setup() {
		const pageRoomStore = usePageRoomStore()
		return { pageRoomStore }
	},
}
</script>

<style scoped lang="scss">
#publish-bar {
	height: 0.6rem;
	padding: 0.1rem;
	// background: $black-05;
	border-bottom: dashed 1px $black-15;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
}
#publish-bar > .act-back {
	margin: -0.05rem;
}
#publish-bar > .act-close {
	position: static;
	margin: -0.05rem;
	margin-left: 0.1rem;
}
#publish-bar > .wrap {
	margin-right: 0.3rem;
}
#publish-bar .filler {
	flex: 1 1;
}
</style>
