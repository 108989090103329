<template>
	<!-- <pre>{{ isMe }}</pre> -->
	<template v-if="loading">
		<div id="room-header">
			<h1>Loading...</h1>
		</div>
	</template>
	<template v-if="room && !loading">
		<!-- Publish bar -->
		<RoomPublishBar v-if="showPublishBar" :room="room" />

		<!-- Featured flag -->
		<BaseFlag
			v-if="$isAdmin && room.featured"
			id="featured-flag"
			text="Featured"
			:class="{ 'bump-top': showPublishBar }"
			:color="featuredOk ? 'green' : 'red'"
			@click="showDialogfeatureStatus"
		/>

		<!-- Edit state -->
		<ClusterEdit
			v-if="$isLoggedIn && editMode == 1"
			:entity="room"
			type="room"
			@exit="toggleEditMode"
			@update="onUpdateRoom"
		/>

		<!-- Rearrange state -->
		<RoomRearrange
			v-else-if="$isLoggedIn && editMode == 2"
			@cancel="toggleEditMode"
			@update="onRearrange"
		/>

		<!-- View state -->
		<div v-show="!($isLoggedIn && editMode)">
			<AdminControls v-if="$isAdmin" entityType="room" :entity="room" />

			<!-- Header -->
			<div id="room-header" :style="styleMaxWidth">
				<!-- Title -->
				<div class="h1-wrap">
					<h1><BaseFlag v-if="!room.isPublished" text="Draft" />{{ room.name }}</h1>

					<!-- Floating sction buttons -->
					<!-- Note: we're not useing FloatActions component because
					the requirements are too specific for rooms. -->
					<div id="float-actions" :class="{ 'publish-bar': showPublishBar }">
						<ActionMenu :options="menuOptions" />
						<ActionLock v-if="room.isPrivate" @click="onClickLock" />
						<ActionClose
							v-if="room.isMe"
							:inline="true"
							:to="{ name: 'UserRooms', params: { username: room.owner.username } }"
						/>

						<!-- Draft & feature flags for mobile screen only -->
						<BaseFlag v-if="!room.isPublished" text="Draft" />
						<BaseFlag
							v-if="$isAdmin && room.featured"
							text="Featured"
							@click="showDialogfeatureStatus"
						/>

						<div class="filler"></div>
						<ActionBack
							v-if="room.isMe"
							:to="{ name: 'UserRooms', params: { username: room.owner.username } }"
						/>
						<ActionLogo v-else />
					</div>
				</div>

				<!-- Description -->
				<BaseRoomDescription :room="room" />

				<!-- Intro -->
				<div v-if="room.intro" id="intro" :class="{ center: room.intro.length < 200 }">
					{{ room.intro }}
				</div>

				<div v-if="!room.artworkCount" class="empty-msg">
					This room is still empty.
				</div>
			</div>

			<!-- Grid -->
			<BaseLoading v-if="!room" />
			<TheArtGrid v-else-if="room.artworkCount" entityType="room" :id="room.id" />
		</div>
	</template>
</template>

<script>
// Stores
import { usePageRoomStore } from '@/stores/PageRoomStore'
import { useApiStore } from '@/stores/ApiStore'
import { useFeedStore } from '@/stores/FeedStore'
import { useHttpErrorStore } from '@/stores/HttpErrorStore'
import { useKeyStore } from '@/stores/KeyStore'
import { useDialogStore } from '@/stores/DialogStore'

// Views
import ClusterEdit from '@/views/Room/ClusterEdit'
import RoomRearrange from '@/views/Room/RoomRearrange'

// Components
import AdminControls from '@/components/AdminControls/AdminControls'
import RoomPublishBar from '@/views/Room/RoomPublishBar'
import BaseFlag from '@/components/BaseFlag'
import TheArtGrid from '@/components/TheArtGrid'
import BaseLoading from '@/components/BaseLoading'
import ActionLogo from '@/components/ActionLogo'
import ActionBack from '@/components/ActionBack'
import ActionClose from '@/components/ActionClose'
import ActionMenu from '@/components/ActionMenu'
import ActionLock from '@/components/ActionLock'
import BaseRoomDescription from '@/components/BaseRoomDescription'

// Vue functions
import { computed, onServerPrefetch, onMounted, onBeforeUnmount, reactive, toRefs, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'

// Internal
import { share } from '@/use/General'
import flash from '@/use/FlashAlert'
import { injectMetaData } from '@/use/MetaData'
import { isSSRClient } from '@/use/Base'
import { baseUrl } from '@/use/BaseUrls'
import { ddSep, privateClusterMsg } from '@/use/Constants'
import { prettyDate } from '@/use/StringMagic'

export default {
	name: 'RoomMain',
	components: {
		ClusterEdit,
		RoomRearrange,
		AdminControls,
		RoomPublishBar,
		BaseFlag,
		TheArtGrid,
		BaseLoading,
		ActionLogo,
		ActionBack,
		ActionClose,
		ActionMenu,
		ActionLock,
		BaseRoomDescription,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
		// 	username: {
		// 		type: String,
		// 		required: true,
		// 	},
		// 	namePath: {
		// 		type: String,
		// 		required: true,
		// 	},
	},
	setup(props) {
		const dialogStore = useDialogStore()

		// Load room
		let {
			loading,
			editMode,
			room,
			featuredOk,
			showPublishBar,
			menuOptions,
			styleMaxWidth,
			onUpdateRoom,
			onRearrange,
			toggleEditMode,
			onClickLock,
			showDialogfeatureStatus,
		} = loadContent(props)

		// Assemble & inject meta data
		createMetaData(room)

		return {
			dialogStore,
			//
			loading,
			editMode,
			room,
			featuredOk,
			showPublishBar,
			menuOptions,
			styleMaxWidth,
			onUpdateRoom,
			onRearrange,
			toggleEditMode,
			onClickLock,
			showDialogfeatureStatus,
		}
	},
}

/**
 * Load room info
 */
function loadContent(props) {
	const pageRoomStore = usePageRoomStore()
	const feedStore = useFeedStore()
	const httpErrorStore = useHttpErrorStore()
	const keyStore = useKeyStore()
	const dialogStore = useDialogStore()
	const router = useRouter()
	const route = useRoute()
	const $myUsername = inject('$myUsername')
	const $isSuperAdmin = inject('$isSuperAdmin')

	// Load API
	const apiStore = useApiStore()
	const clusterApi = apiStore.loadApi('cluster')
	const userApi = apiStore.loadApi('user')

	// Reactive data
	const state = reactive({
		loading: !isSSRClient,
		editMode: 0, // 1 = edit text / 2 = rearrange
		room: computed(() => pageRoomStore.room),
		featuredOk: computed(() => {
			if (!state.room) return true
			const isPublic = !state.room.isPrivate
			const isPublished = state.room.isPublished
			const hasEnoughItms = state.room.artworkCount >= 4
			return isPublic && isPublished && hasEnoughItms
		}),
		showPublishBar: computed(() => {
			return (
				state.room &&
				state.room.isMe &&
				!state.editMode &&
				(!state.room.isPublished || pageRoomStore.forceShow)
			)
		}),
		// Menu options
		menuOptions: computed(() => {
			if (!state) return []
			const generalOptions = [
				{
					value: 'share',
					display: 'Share',
					onSelect: () => {
						share({
							entityType: 'room',
							entity: pageRoomStore.room,
							url: baseUrl + route.path,
						})
					},
				},
			]
			const ownerOptions = [
				{
					value: 'edit',
					display: 'Edit',
					onSelect: toggleEditMode,
				},
				{
					value: 'rearrange',
					display: 'Rearrange',
					onSelect: _rearrangeMode,
				},
				{
					value: 'publish-settings',
					display: 'Publish Settings',
					onSelect: pageRoomStore.showBar,
				},
				{
					value: 'delete',
					display: 'Delete',
					onSelect: _showDialogDelete,
				},
				ddSep,
				{
					value: 'exit',
					display: 'Exit',
					onSelect: _exit,
				},
			]
			const isFeatured = state.room && state.room.featured
			const editorialOptions = [
				{
					value: 'feature',
					display: isFeatured ? 'Unfeature' : 'Feature',
					onSelect: () => _showDialogFeature(isFeatured),
				},
			]
			const options = [...generalOptions]
			if (state.room && state.room.isMe) options.push(ddSep, ...ownerOptions)
			if ($isSuperAdmin) options.push(ddSep, ...editorialOptions)
			return options
		}),
		// isMe: computed(() => (state.room ? state.room.isMe : null)),
		styleMaxWidth: computed(() => {
			const maxWidth = feedStore.maxWidth
			if (!maxWidth) return {}
			return {
				'max-width': (maxWidth - 120) / 100 + 'rem',
				'margin-left': 'auto',
				'margin-right': 'auto',
			}
		}),

		// Callback when room text is updated.
		onUpdateRoom() {
			console.log('--onUpdateRoom')
			_loadPage()
			state.editMode = 0
		},

		// Callback when room image order is updated.
		async onRearrange() {
			await feedStore.refreshFeed()
			state.editMode = 0
		},
	})

	// Server-side initiation
	onServerPrefetch(_loadPage)

	// Client-side initiation
	onMounted(() => {
		// Only load page if it wasn't already fetched server-side (development)
		const isHydrating = !!pageRoomStore.room
		if (!isHydrating) _loadPage()
	})

	onBeforeUnmount(() => {
		// Required to avoid the last visited itm to linger after you return from another page.
		pageRoomStore.$reset()
	})

	// Return data
	return {
		...toRefs(state),
		toggleEditMode,
		onClickLock,
		showDialogfeatureStatus,
	}

	//
	//

	/**
	 * Public functions
	 */

	// Toggle edit mode
	function toggleEditMode() {
		window.scrollTo(0, 0)
		state.editMode = !state.editMode
	}

	// Message about privacy settings.
	function onClickLock() {
		const msg = privateClusterMsg()
		const settings = state.showPublishBar
			? { hideCancel: true }
			: { cancelText: 'Settings', onCancel: pageRoomStore.showBar }
		dialogStore.dialog(msg, {
			title: 'This Room is Private',
			html: true,
			...settings,
		})
	}

	function showDialogfeatureStatus() {
		const status = {
			isPublic: !state.room.isPrivate,
			isPublished: state.room.isPublished,
			hasEnoughItms: state.room.artworkCount >= 4,
		}
		const statusMsg =
			!status.isPublic || !status.isPublished || !status.hasEnoughItms
				? '<p class="error-msg">It is currently hidden from the editorial feed because it does not meet all publishing requirements.<p>'
				: ''
		const statusList = [
			'<ul>',
			`<li class="${status.isPublic ? 'ok' : 'not-ok'}">Public</li>`,
			`<li class="${status.isPublished ? 'ok' : 'not-ok'}">Published</li>`,
			`<li class="${status.hasEnoughItms ? 'ok' : 'not-ok'}">At least 4 images</li>`,
			'</ul>',
		].join('')

		// HTML
		const msg = [
			`<p>This room was featured on:<br><span class="large">${prettyDate(
				state.room.featured,
				true
			)}</span></p>`,
			statusMsg,
			statusList,
		].join('')
		dialogStore.dialog(msg, { title: 'Feature Status', hideCancel: true, okText: 'Done', html: true })
	}

	//
	//

	/**
	 * Private functions
	 */

	// Load page
	// Loads room, then user, rerouts when not found
	async function _loadPage() {
		const { status, statusText } = await _loadRoom()

		// When no room is found, forward to user page or show HTTP error
		//
		// NOTE / TO DO: _loadUser is no longer used since rooms adopted the
		// /room/<id> URL structure (we can't decuct the user from the URL when
		// the room no longer exists) but I'm keeping this here because I want
		// to recycle this after we've implemented user.deletedRooms which will
		// hold the ids of all deleted rooms, which will allow us to still forward
		// to the user after a room was deleted.
		if (status != 200) {
			const { status: userStatus, data: userData } = await _loadUser()
			if (userStatus == 200) {
				_forwardToUser(userData.user.name)
			} else {
				_throw404(status, statusText)
			}
		}
		state.loading = false
	}

	async function _loadRoom() {
		const { id } = props
		const { status, data, statusText } = await clusterApi.getRoom(id)

		if (status == 200 && data) pageRoomStore.storeRoom(data)
		return { status, statusText }
	}

	async function _loadUser() {
		const { username } = props
		return userApi.get(username)
	}

	function _forwardToUser(name) {
		flash(`There is no room <em>'${props.namePath}'</em> by ${name}.`, {
			title: 'Room Not Found',
			blink: true,
			type: 'error',
			html: true,
			// persistent: true,
		})
		router.push({
			name: 'UserRooms',
			params: { username: props.username },
		})
	}

	function _throw404(status, statusText) {
		httpErrorStore.setError({
			status,
			statusText,
			type: 'room',
			origin: 'Room.vue',
		})
	}

	// Activate rearrange mode.
	function _rearrangeMode() {
		const loaded = feedStore.itms.length
		const total = feedStore.total
		if (total > 500) {
			// Abort - Room too large
			dialogStore.dialog('This room has over 500 images and is unfortunately too big to rearrange.', {
				title: `Room Too Large (${total})`,
				hideCancel: true,
			})
		} else if (total > 100) {
			// Warning - Room very large
			dialogStore.confirm(
				_continue(true),
				[
					`<p>This room has ${total} images in total. To rearrange them, we'll have `,
					`to load all of them at once, which might slow down your browser.</p>`,
					`<p>Are you sure you wish to continue?</p>`,
				].join(''),
				{
					title: 'Large Room',
					html: true,
				}
			)
		} else if (total > loaded) {
			// Need to load more pages
			_continue(true)
		} else {
			// All images are loaded
			_continue()
		}

		async function _continue(loadAll) {
			if (loadAll) await feedStore.refreshFeed({ loadAll: true })
			state.editMode = 2
		}
	}

	// Delete
	function _showDialogDelete() {
		dialogStore.confirmCritical(async () => {
			await clusterApi.delete(state.room.id)
			flash(`Your room <em>'${state.room.name}'</em> has been successfully deleted.`, {
				title: 'Room Deleted',
				type: 'success',
				html: true,
			})
			router.push({ name: 'UserRooms', params: { username: $myUsername } })
		})
	}

	// Return to rooms overview
	function _exit() {
		const route = { name: 'UserRooms', params: { username: state.room.owner.username } }
		if (keyStore.mod) {
			window.open(route.path)
		} else {
			router.push(route)
		}
	}

	function _showDialogFeature(isFeatured) {
		const msg = isFeatured
			? 'Are you sure you want to remove this room from the Arthur editorial feed?'
			: 'Are you sure you want to feature this room on the Arthur editorial feed?'
		dialogStore.confirm(
			() => {
				isFeatured ? pageRoomStore.unfeature() : pageRoomStore.feature()
			},
			msg,
			{ title: `${isFeatured ? 'Unfeature' : 'Feature'} This Room` }
		)
	}
}

/**
 * Assemble & inject meta data
 */
// prettier-ignore
function createMetaData(room) {
	// Construct meta data
	const title = computed(() => (room.value ? room.value.name : null))
	const description = computed(() =>
		room.value
			? `A ${room.value.isPrivate ? 'private ' : ''}room by ${room.value.owner.name}.`
			: 'A curated room on Arthur.'
	)

	const imgSrc = computed(() => room.value && room.value.previewArtwork ? 'https://arthur.io' + room.value.previewArtwork.views[0].src.replace(/__SIZE__/, 'large') : null)
	const imgWidth = computed(() => room.value && room.value.previewArtwork ? room.value.previewArtwork.views[0].width : null)
	const imgHeight = computed(() => room.value && room.value.previewArtwork ? room.value.previewArtwork.views[0].height : null)


	// Inject meta data
	injectMetaData({ title, description, imgSrc, imgWidth, imgHeight })
}
</script>

<style lang="scss" scoped>
/**
 * Buttons
 */
#featured-flag {
	position: absolute;
	top: 0.1rem;
	right: 0.1rem;
	cursor: pointer;
	z-index: 1;
}
#featured-flag.bump-top {
	margin-top: 0.6rem;
}
#float-actions {
	position: fixed;
	top: 0.5rem;
	left: 0.5rem;
	right: 0.5rem;
	z-index: 1;
	display: flex;
	flex-direction: row-reverse;
	// background: rgba(255, 0, 0, 0.1);
	pointer-events: none;
}
#float-actions.publish-bar {
	top: calc(var(--pp) + 0.6rem - 0.1rem);
}
#float-actions .filler {
	flex: 1;
}
#float-actions > div:not(.filler) {
	pointer-events: auto;
}
#float-actions .flag {
	display: none;
	margin: 0.15rem 0.1rem 0.15rem 0;
}
#float-actions .act-close {
	display: none;
}

/**
 * Room Header
 */
#room-header {
	box-sizing: content-box;
	max-width: 8rem;
	margin: 0 auto;
	padding: var(--pp);
	padding-bottom: 0;
	// position: relative;
}
#room-header .h1-wrap {
	display: flex;
	margin-bottom: 0.2rem;
}
#room-header h1 .flag {
	margin-right: 0.1rem;
	word-wrap: normal;
	position: relative;
	top: -0.03rem;
}

#room-header .h1-wrap h1 {
	flex: 1 1;
	margin-bottom: 0;
}
#room-header .empty-msg {
	margin-top: 0.4rem;
	color: $black-30;
}

// Intro
#intro {
	margin-top: 0.3rem;
	margin-bottom: 0.4rem;
	max-width: 6rem;
	white-space: pre-line;
	text-align: left;
}

/**
 * Gallery
 */

#gallery {
	max-width: 9.2rem;
	padding-top: 0.4rem;
}
/**
 * Responsive
 */

@media only screen and (max-width: 1150px) {
	// Stick buttons to top right.
	#float-actions {
		position: static;
		margin: -0.1rem;
		margin-right: -0.1rem;
		margin-left: 0.1rem;
		// background: pink;
	}
	#float-actions .act-back,
	#float-actions .filler {
		display: none;
	}
	#float-actions .act-close {
		display: block;
	}
}

@media only screen and (min-width: $tablet_) {
	// 60px padding around the grid.
	#gallery:deep() .grid {
		padding-left: 60px;
	}
	#gallery.center:deep() .grid {
		padding-right: 60px;
	}
}
@media only screen and (max-width: $tablet) {
	#room-header {
		word-wrap: anywhere; // In case there's very long words in title.
	}
}
@media only screen and (max-width: $mobile) {
	#room-header {
		// background: pink;
		text-align: center;
		margin-top: 0.8rem;
	}

	// Buttons
	#float-actions {
		margin: 0;
		position: fixed;
		top: calc(var(--pp) - 0.1rem);
		right: calc(var(--pp) - 0.1rem);
		left: calc(var(--pp) - 0.1rem);
	}
	#float-actions .act-close {
		display: none;
	}
	#float-actions .act-back,
	#float-actions .filler {
		display: block;
	}

	// Switch location of featured flag
	#featured-flag {
		display: none;
	}

	// Switch location of draft flag.
	#room-header h1 .flag {
		display: none;
	}
	#float-actions .flag {
		display: block;
	}

	// Intro
	#intro.center {
		text-align: center;
	}
}
</style>
