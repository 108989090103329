<template>
	<div class="act-lock" :class="{ dark }">
		<BaseIcon name="lock" />
	</div>
</template>

<script>
// Components
import BaseIcon from '@/components/BaseIcon'

export default {
	name: 'ActionLock',
	components: { BaseIcon },
	props: {
		dark: Boolean,
	},
}
</script>

<style scoped lang="scss">
.act-lock {
	width: 0.5rem;
	height: 0.5rem;
	padding: 0.15rem;
	cursor: pointer;
}

// Dark version
.act-lock.dark:deep() .icn {
	fill: $white-75;
}

@media (hover: hover) {
	.act-lock:hover:deep() .icn {
		fill: $primary;
	}
}
</style>
