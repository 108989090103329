<template>
	<div class="content-pad">
		<h1 id="title">Rearrange Images</h1>
		<ActionClose @click="$emit('cancel')" />

		<div class="structured-form">
			<div v-if="errorMsg" class="error-msg">{{ errorMsg }}</div>
			<div class="buttons-wrap">
				<FormButton :level="1" :wait="waitingSubmit" value="Submit" @click="submit" />
				<FormButton :level="2" value="Cancel" @click="$emit('cancel')" />
			</div>
		</div>

		<p>Drag images to rearrange them.</p>
		<RearrangeImages :itms="feedStore.itms" :removable="false" imgSize="small" @rearrange="onRearrange" />
	</div>
</template>

<script>
// Stores
import { useFeedStore } from '@/stores/FeedStore'
import { useApiStore } from '@/stores/ApiStore'
import { usePageRoomStore } from '@/stores/PageRoomStore'

// Components
import ActionClose from '@/components/ActionClose'
import RearrangeImages from '@/components/RearrangeImages'
import FormButton from '@/components/FormButton'

// Internal
import flash from '@/use/FlashAlert'

export default {
	name: 'RoomRearrange',
	components: {
		ActionClose,
		RearrangeImages,
		FormButton,
	},
	emits: ['update', 'cancel'],
	setup() {
		const feedStore = useFeedStore()
		const pageRoomStore = usePageRoomStore()
		const apiStore = useApiStore()
		const clusterApi = apiStore.loadApi('cluster')
		return { feedStore, pageRoomStore, clusterApi }
	},
	data() {
		return {
			imgOrder: null,
			errorMsg: null,
			waitingSubmit: false,
		}
	},
	methods: {
		onRearrange(order) {
			this.imgOrder = order
		},
		async submit() {
			if (this.imgOrder) {
				this.waitingSubmit = true

				const response = await this.clusterApi.updateImgOrder({
					id: this.pageRoomStore.roomId,
					imgOrder: this.imgOrder,
				})
				const { status, statusText } = response || {}

				if (status == 200) {
					flash('Images successfully rearranged.', { type: 'success', expiration: 1500 })
					this.$emit('update', this.imgOrder)
				} else {
					this.errorMsg = statusText
				}

				this.waitingSubmit = false
			} else {
				this.$emit('cancel')
			}
		},
	},
}
</script>

<style scoped lang="scss">
.act-close:not(.inline) {
	top: calc(var(--pp) - 0.1rem);
	right: calc(var(--pp) - 0.1rem);
}
.structured-form {
	flex-direction: column;
	margin-bottom: 0.5rem;
}
.structured-form + p {
	margin-bottom: 0.18rem;
}
.error-msg {
	margin-bottom: 0.2rem;
}
.buttons {
	margin-bottom: 0.3rem;
}
</style>
