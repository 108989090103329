<template>
	<div class="act-back" :class="{ dark }" @click="onClick">
		<BaseIcon name="arrow-left" />
	</div>
</template>

<script>
// Stores
import { useKeyStore } from '@/stores/KeyStore'

// Components
import BaseIcon from '@/components/BaseIcon'

export default {
	name: 'ActionBack',
	components: { BaseIcon },
	props: {
		to: Object,
		//
		dark: Boolean,
	},
	setup() {
		const keyStore = useKeyStore()
		return { keyStore }
	},
	methods: {
		onClick() {
			if (!this.to) return
			if (this.keyStore.mod) {
				window.open(this.to.path)
			} else {
				this.$router.push(this.to)
			}
		},
	},
}
</script>

<style scoped lang="scss">
.act-back {
	width: 0.5rem;
	height: 0.5rem;
	padding: 0.15rem;
	cursor: pointer;
}

// Dark version
.act-back.dark:deep() .icn {
	fill: $white-75;
}

@media (hover: hover) {
	.act-back:hover:deep() .icn {
		fill: $primary;
	}
}
</style>
